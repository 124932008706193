import React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";

import { TestimonialVideoPage as seo } from "../helpers/seo";

import SEO from "../components/SEO";
import ResponsiveVideo from "../components/ResponsiveVideo";

const id = "dz4vTo4q34I";

const TestimonialVideoPage: React.FC = () => (
  <ModalRoutingContext.Consumer>
    {({ modal }) => (
      <>
        <SEO title={seo.title} description={seo.description} />
        {modal ? (
          <ResponsiveVideo youtubeId={id} className="w-[640px] max-w-full" />
        ) : (
          <div className="flex items-center justify-center p-4">
            <div className="w-[640px] max-w-full mt-8 mb-16">
              <ResponsiveVideo youtubeId={id} className="mb-8" />
              <Link to="/">« Return to Home Page</Link>
            </div>
          </div>
        )}
      </>
    )}
  </ModalRoutingContext.Consumer>
);

export default TestimonialVideoPage;
