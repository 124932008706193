import React from "react";
import YouTube from "react-youtube";

const ResponsiveVideo: React.FC<{
  youtubeId: string;
  className?: string;
}> = ({ youtubeId, className = "" }) => (
  <YouTube
    containerClassName={`relative w-full h-0 pb-1080p ${className}`}
    className="absolute w-full h-full top-0 left-0"
    opts={{ width: "1920", height: "1080" }}
    videoId={youtubeId}
  />
);

export default ResponsiveVideo;
